import { javascript } from '@api/index'
import {
  StoreMode,
  useDefaultDocxSettings,
  useDocxSettings,
  useHydration
} from '@stores/utils'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export type CustomClauseVariantStoreType = ReturnType<
  typeof useCustomClauseVariantStore
>
export const allCustomClauseVariantStores = ref<
  Array<CustomClauseVariantStoreType>
>([])

export const createCustomClauseVariantStore = (uniqueId: string | number) => {
  const customClauseVariantStore = defineStore(
    'custom-clause-variant-store-' + String(uniqueId),
    () => {
      const hydration = useHydration<Backend.Models.CustomClauseVariant>(
        javascript.customClauseVariant
      )
      const hydrateById = (
        id: number,
        fields: Array<keyof Backend.Models.CustomClauseVariant> = []
      ) => {
        return hydration.hydrate({ id }, fields)
      }
      allCustomClauseVariantStores.value.push(
        customClauseVariantStore as unknown as CustomClauseVariantStoreType
      )

      const $default = () => {
        hydration.hydratedData.value = {
          id: -1,
          docx_settings: useDefaultDocxSettings()
        } as unknown as Backend.Models.CustomClauseVariant
        hydration.hydrated.value = true
        hydration.storeMode.value = StoreMode.NewData
      }

      return {
        ...hydration,
        hydrateById,
        id: hydration.hydratedComputed('id'),
        $default,
        ...useDocxSettings(hydration)
      }
    }
  )
  return customClauseVariantStore
}

export const useCustomClauseVariantStore =
  createCustomClauseVariantStore('current')
