import {type Ref, ref, watch, type WatchStopHandle} from "vue";
import _debug from "debug"
const debug = _debug("app:resize-observer")

/**
 * Creates a reactive ResizeObserver for a Vue ref element.
 * This utility function allows for the reactive tracking of an element's width and height,
 * updating these values whenever the observed element's size changes.
 *
 * @param {Ref<Element>} element - A Vue ref to the DOM element to be observed.
 * @param {Ref<boolean>} active - A Vue ref indicating whether the observer is active.
 *
 * @returns An object containing reactive properties `width` and `height` of the observed element.
 *
 * Usage:
 * - The `element` parameter should be a Vue ref pointing to a DOM element.
 * - The `active` parameter controls the activation of the observer; it can be toggled to start or stop observation.
 * - The function returns an object with two reactive properties, `width` and `height`, which update in response to changes in the element's size.
 *
 * Note:
 * - The observer automatically starts observing the element upon activation (`active` set to true) and stops observing when deactivated.
 * - It's important to ensure that the element exists in the DOM before attempting to observe it to avoid errors.
 */
export const useResizeObserver = (element: Ref<Element>, active = ref(true)) => {
  const width = ref(element.value?.clientWidth ?? 0)
  const height = ref(element.value?.clientHeight ?? 0)
  let resizer: ResizeObserver | undefined;
  let stopWatcher: WatchStopHandle | undefined

  watch(active, (value) => {

    if(value) {
      resizer = new ResizeObserver(([entry]) => {
        width.value = entry.contentRect.width
        height.value = entry.contentRect.height
        debug(`Width changed to ${width.value}, Height changed to ${height.value}`)
      })

      stopWatcher = watch(element, (value, oldValue) => {
        if(value) {
          width.value = value.clientWidth
          height.value = value.clientHeight
          if(resizer) {
            resizer.observe(value)
          }
          debug("Observing element", value)
        }

        if(oldValue) {
          if(resizer) {
            resizer.unobserve(oldValue)
          }
          debug("Unobserving element", value)
        }
      }, {flush: 'post', immediate: true})
    } else {
      if(stopWatcher) stopWatcher()
      if(resizer) resizer.disconnect()
      stopWatcher = undefined
      resizer = undefined
    }

  }, {immediate: true})

  return {width, height}
}