<template>
  <div
    ref="inputElement"
    class="p-2 bg-primary-10 text-sm text-secondary shadow-small rounded-lg border border-secondary-40 focus-within:border-tertiary-80"
    contenteditable="true"
    tabindex="-1"
    @click="onClick"
    @paste.prevent
    @keydown.enter.prevent
  ></div>
  <div class="flex flex-wrap gap-1 whitespace-nowrap">
    <DButton
      text-sm
      @click="addFormat('number')"
      v-text="localization(`editor.dialogs.crossref.editor.number`)"
    />
    <DButton
      text-sm
      @click="addFormat('fullnumber')"
      v-text="
        localization(`editor.dialogs.crossref.editor.number_with_context`)
      "
    />
    <DButton
      text-sm
      @click="addFormat('relativenumber')"
      v-text="localization(`editor.dialogs.crossref.editor.number_relative`)"
    />
    <DButton
      text-sm
      @click="addFormat('abovebelow')"
      v-text="localization(`editor.dialogs.crossref.editor.above_below`)"
    />
    <DButton
      text-sm
      @click="addFormat('paragraph')"
      v-text="localization(`editor.dialogs.crossref.editor.para`)"
    />
    <DButton
      text-sm
      @click="addFormat('documentname')"
      v-text="localization(`editor.dialogs.crossref.editor.document_name`)"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, watch, inject } from 'vue'
import DButton from '../../components/DButton.vue'
import { TinyEditor } from '@avvoka/shared'
import { CrossReferenceUtils } from './CrossReferenceUtils'

const localization = inject<VueInjectLocalization>('localization')
const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])
const tinyEditor = new TinyEditor()
const inputElement = ref()

tinyEditor.onChange.push(() => {
  emit(
    'update:modelValue',
    CrossReferenceUtils.convertToFormat(inputElement.value!.innerHTML)
  )
})

watch(
  inputElement,
  (value) => {
    if (value != null) tinyEditor.destroy()
  },
  { flush: 'pre' }
)

watch(
  inputElement,
  (value) => {
    value.innerHTML = CrossReferenceUtils.convertToHtml(props.modelValue)
    tinyEditor.initialize(value, false)
  },
  { flush: 'post' }
)

watch(
  props,
  () => {
    const newValue = props.modelValue
    const current = inputElement
      .value!.innerHTML.replace(/(\uFEFF|&#65279;)/g, '')
      .replace(/\u200B/g, '')
      .replace(/&#8203;/g, '')
    const wanted = CrossReferenceUtils.convertToHtml(newValue)
      .replace(/(\uFEFF|&#65279;)/g, '')
      .replace(/\u200B/g, '')
      .replace(/&#8203;/g, '')
    if (current !== wanted)
      inputElement.value!.innerHTML =
        CrossReferenceUtils.convertToHtml(newValue)
  },
  { flush: 'posh' }
)

const addFormat = (formatName) => {
  const formatEl = document.createElement('crf')
  formatEl.insertBefore(document.createTextNode('\uFEFF'), null)
  formatEl.insertBefore(
    document.createTextNode(CrossReferenceUtils.getFormatName(formatName)),
    null
  )
  formatEl.insertBefore(document.createTextNode('\uFEFF'), null)

  formatEl.setAttribute('contenteditable', 'false')
  formatEl.setAttribute('value', formatName)
  formatEl.setAttribute(
    'title',
    localization('editor.dialogs.crossref.editor.click_to_del')
  )
  tinyEditor.insertNodeAtCursor(document.createTextNode('\u200B'))
  tinyEditor.insertNodeAtCursor(formatEl)
  tinyEditor.insertNodeAtCursor(document.createTextNode('\u200B'))
}

const onClick = (event) => {
  let current: any = event.target as Node
  let parent: any = (event.target! as Node).parentNode
  while (inputElement.value!.contains(current)) {
    if (current.nodeName === 'CRF') {
      parent.removeChild(current)
    } else {
      current = parent
      parent = parent.parentNode as Node
    }
  }
}
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'CrossrefEditor' })
</script>
<style>
crf {
  display: inline-block;
  background-color: var(--avv-secondary--color);
  color: var(--avv-primary-light--color);
  padding: 0 5px;
  cursor: pointer;
  font-size: 12px;
  margin: 2px;
}
</style>
