import {
  ascendantBlot,
  ascendantLineBlot,
  descendantBlots,
  Scroll
} from '@avvoka/editor'
import type { EditorStoreType } from '@stores/features/editor.store'
import { computed, type Ref } from 'vue'
import type {
  BlotName,
  MountedEditor,
  SuccessSelectionRange
} from '@avvoka/editor'
import {NegoUtils} from "../../../javascripts/editors/nego";

export const enum Tab {
  Home = 'Home',
  Insert = 'Insert',
  Styles = 'Styles',
  Review = 'Review',
  Automation = 'Automation'
}

const useParticipantInfo = (bridge: EditorStoreType) => {
  const currentParticipant = bridge.currentParticipant.hydrated
    ? bridge.currentParticipant
    : {
        party: '',
        name: '',
        isController: false,
        canSign: false,
        canEdit: false,
        edit: 'No',
        rights: 'view-only'
      }

  const currentParty = currentParticipant.party
  const isController = currentParticipant.isController
  const isView = !currentParticipant.canEdit
  const noSign = !currentParticipant.canSign
  const participantRights = computed(() => currentParticipant.rights)

  const isMultiParty = bridge.parties.length > 1
  const joinedCounterParties = bridge.parties
    .filter((party) => party !== currentParty)
    .join(' ,')
  const partiesString = bridge.parties
    .filter((p) => p !== currentParty)
    .join(', ')
  const partyQuantityKey = `${isMultiParty ? 'multi' : 'single'}_party`

  const tickToComplete = window.AvvStore.state.tick_to_complete
  const actionString = window.AvvStore.state.tick_to_complete
    ? 'completing'
    : 'signing'

  const getAction = () => {
    if (tickToComplete && !noSign) return 'complete'
    if (noSign) return 'accept'
    return 'sign'
  }

  return {
    currentParty,
    isController,
    isView,
    joinedCounterParties,
    partyQuantityKey,
    participantRights,
    noSign,
    tickToComplete,
    partiesString,
    actionString,
    isMultiParty,
    getAction
  }
}

export const getToolbarMessage = (
  documentLock: Ref<{ lockedToState: string; lockedTo: string }>,
  isNego: boolean,
  bridge: EditorStoreType
) => {
  if (!isNego) return ''

  const {
    joinedCounterParties,
    currentParty,
    isController,
    partyQuantityKey,
    isView,
    participantRights
  } = useParticipantInfo(bridge)

  const messages = {
    multi_party: {
      controller: {
        view: {
          lock: `To take control of this document, click here. You’ll need to send it back to ${joinedCounterParties} once you’ve finished`
        },
        lock: `Click here to take control of the document so that your team (${currentParty}) can start editing`
      },
      lock: `A Controller from ${currentParty} needs to unlock the document if you wish to start editing`
    },
    single_party: {
      controller: {
        view: {
          lock: `Click here to take control of the document so that your team (${currentParty}) can start editing`
        },
        lock: `Click here to take control of the document so that your team (${currentParty}) can start editing`
      },
      lock: `A Controller from ${currentParty} needs to unlock the document if you wish to start editing`
    }
  }

  return computed(() => {
    switch (documentLock.value.lockedToState) {
      case 'cp':
        return `${documentLock.value.lockedTo} has control of the document. You can't make edits until they send the document back to you`
      case 'none':
        return isController
          ? isView
            ? messages[partyQuantityKey as keyof typeof messages].controller
                .view.lock
            : messages[partyQuantityKey as keyof typeof messages].controller
                .lock
          : messages[partyQuantityKey as keyof typeof messages].lock
      case 'me':
        return `You have ${participantRights.value} permission on this document`
    }
  })
}

export const getTooltipMessage = (
  documentLock: Ref<{ lockedToState: string; lockedTo: string }>,
  isNego: boolean,
  bridge: EditorStoreType
) => {
  if (!isNego) return ''

  const {
    tickToComplete,
    noSign,
    joinedCounterParties,
    currentParty,
    isController,
    isView,
    partyQuantityKey,
    getAction
  } = useParticipantInfo(bridge)

  const messages = {
    multi_party: {
      controller: {
        view: {
          lock: `Taking control of the document will stop ${joinedCounterParties} from making edits. If you continue, you won’t be able to ${getAction()} the document until you send it back to ${joinedCounterParties}`
        },
        lock: `No party is currently editing the document. If you click the bar to begin editing, ${joinedCounterParties} will not be able to make edits`
      },
      lock: `No party is currently editing the document. Access the Parties sidebar to see who is a Controller from ${currentParty} on the document`
    },
    single_party: {
      controller: {
        view: {
          lock: `You are not currently editing the document. Clicking the bar will let you take control of the document, in order to make edits`
        },
        lock: `You are not currently editing the document. Clicking the bar will let you take control of the document, in order to make edits`
      },
      lock: `You are not currently editing the document. A controller can click the bar to take control of the document, in order to make edits`
    }
  }

  return computed(() => {
    switch (documentLock.value.lockedToState) {
      case 'cp':
        return `You will be notified via email when ${documentLock.value.lockedTo} sends the document back to you`
      case 'none':
        return isController
          ? isView
            ? messages[partyQuantityKey as keyof typeof messages].controller
                .view.lock
            : messages[partyQuantityKey as keyof typeof messages].controller
                .lock
          : messages[partyQuantityKey as keyof typeof messages].lock
      case 'me':
        switch (bridge.currentParticipant?.edit.toLowerCase()) {
          case 'no':
            return 'You are only able to view this document'
          case 'data':
            return 'Click the questionnaire button in the top left to amend any inputs'
          case 'comments':
            return 'To start making bubble comments, highlight some text'
        }
        break
    }
  })
}

export const unlockDocument = (
  editor: MountedEditor,
  bridge: EditorStoreType
) => {
  if (editor.negotiation.lock.lockedToState === 'none') {
    const {
      partiesString,
      getAction,
      isController,
      isView,
      isMultiParty,
      actionString
    } = useParticipantInfo(bridge)

    const message = `If you take control of the document, ${partiesString} will no longer be able to make edits and you’ll need to <b>send the document back to them before you can ${getAction()}</b>`
    const ending =
      isController && isView && !isMultiParty
        ? 'accepted'
        : `sent the document to ${partiesString}`
    const showMessage = isController && isView && isMultiParty
    window.avv_dialog({
      squareDisplay: true,
      warn: showMessage,
      confirmTitle: 'Are you sure you want to take control of the document?',
      confirmMessage: showMessage
        ? message
        : `If you so do, you might be unable to take certain actions such as ${actionString} the document until you have ${ending}`,
      okButtonText: "I'll take control",
      confirmCallback: (val: boolean) => {
        if (val) {
          void NegoUtils.unlock(editor)
        }
      }
    })
  }
}

export const getBlotDropdownAttributeValue = <T>(
  selection: SuccessSelectionRange,
  blotName: BlotName,
  attributeName: string,
  defaultValue: T,
  bridge: EditorStoreType,
  scroll: Scroll
) => {
  if (selection.isCollapsed) {
    const blot = ascendantBlot(
      selection.blotAtStart,
      (b) => b.statics.blotName === blotName
    )
    if (blot) {
      return { value: blot.attributes[attributeName] ?? defaultValue }
    }
    const avvStyle = ascendantLineBlot(selection.blotAtStart)?.attributes[
      'data-avv-style'
    ]
    if (avvStyle) {
      bridge.styles
      const definition = bridge.styles.formats[avvStyle]?.definition
      if (definition && definition[blotName]) {
        return {
          value: definition[blotName][attributeName] ?? defaultValue
        }
      }
    }

    return {
      value: (bridge.defaultStyle?.definition?.[blotName]?.[attributeName] ??
        defaultValue) as string
    }
  } else {
    const blots = descendantBlots(scroll, selection.start, selection.length)
    const value =
      bridge.defaultStyle?.definition?.[blotName]?.[attributeName] ??
      defaultValue
    const values = new Set<string>()
    blots
      .filter((blot) => blot.statics.blotName === blotName)
      .forEach((blot) => values.add(blot.attributes[attributeName]))
    values.add(value)
    return { values }
  }
}
